import { Button, Spacer } from '../Layout/Layout.styled';
import { StyledAnimation } from './AnimationPage.styled';
import { StyledSection } from '../LegalsPage/LegalsPage.styled';

export const AnimationPage = ({ triggerFunc }: { triggerFunc: () => void}) =>
  <StyledSection>
    <StyledAnimation src={'animation.gif'} />
    <Spacer size={20} />
    <Button isMain onClick={triggerFunc}>Let's start</Button>
  </StyledSection>;
