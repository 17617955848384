import { HomeButtons } from '../components/HomeButtons/HomeButtons';
import { useEffect, useState} from 'react';
import { LegalsPage } from 'components/LegalsPage/LegalsPage';
import { AnimationPage } from 'components/AnimationPage/AnimationPage';
import Cookies from "js-cookie";

const Root = () => {
  const jwt = Cookies.get("jwt");

  const [ isAnimationVisible, setAnimationVisible ] = useState(false);
  const [ isNavigationVisible, setNavVisible ] = useState(false);

  // commented out to always start from the first page
  // useEffect(() => {
  //   if(jwt) {
  //     setNavVisible(true);
  //     setAnimationVisible(false);
  //   }
  // }, [jwt]);

  if (isAnimationVisible) {
    return <AnimationPage triggerFunc={() => [setNavVisible(true), setAnimationVisible(false)]}/>;
  }

  if (isNavigationVisible) {
    return <HomeButtons />;
  }

  return <LegalsPage triggerFunc={() => setAnimationVisible(true)} />;
};

export default Root;
