import styled from 'styled-components';

export const StyledHomeButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

interface Props {
    option: 'up' | 'down' | 'return',
    image: string,
    active: boolean
}

export const StyledButton = styled.button<Props>`
    @keyframes pulse-white {
        0% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }
    
        70% {
            transform: scale(1.1);
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }
    
        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }

    @keyframes pulse-white-rot {
        0% {
            transform: scale(0.9) rotate(180deg);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }

        70% {
            transform: scale(1.1) rotate(180deg);
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }

        100% {
            transform: scale(0.9) rotate(180deg);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }
    
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  border: none;
  height: 150px;
  width: 150px;
  background-image: ${props => `url("${props.image}")`};
  transition: scale 3s linear;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  color: transparent;
  user-select: none; /* 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    animation: ${props => props.active ? `${props.option === 'down' ? 'pulse-white-rot':'pulse-white'} 3s infinite`:''};
    
  &:disabled {
    background: gray;
  }
`;