import styled from 'styled-components';

export const StyledLayout = styled.div`
  background: #f2f0e0;
  min-height: 100vh;
`;

export const StyledMain = styled.main`
  margin: 30px 0;
`;

export const StyledHeader = styled.header`
  display: flex;
  padding: 30px 10px;
  justify-content: center;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0.1),
    0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background: #f7f5ec;
`;

export const Logo = styled.img`
  width: calc(100% / 3);
  max-width: 240px;
`;

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 30px 20px;
  max-width: 700px;
  margin: 30px auto 0;
  border-top: 1px solid #80808057;
`;

export const StyledFooterLogo = styled.img`
  max-width: 200px;
`;

export const TextRegular = styled.div`
  font-size: 16px;
  line-height: 1.625;
`;

export const TextMedium = styled.p`
  font-size: 14px;
  line-height: 1.625;
`;

export const Button = styled.button<{ isMain: boolean }>`
  background: ${props => props.isMain ? '#034a25':'transparent'};
  border: 1px solid ${props => props.isMain ? '#034a25':'#5a6d88'};
  color: ${props => props.isMain ? '#fff':'#5a6d88'};
  padding: 8px 12px;
  line-height: 1.5;
  text-transform: uppercase;
  transition: all 0.4s ease-in;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  
  &:hover {
    background: ${props => props.isMain ? '#000':'#5a6d88'};
    border: 1px solid ${props => props.isMain ? '#000':'#5a6d88'};
    color: #fff;
    transform: scale(1.05);
  }
`;

export const Spacer = styled.div<{ size: number }>`
  width: 100%;
  height: ${props => `${props.size}px`};
`;

export const StyledFooterPhone = styled.a`
  font-size: 1.5em; 
  color: #3a3b3b;
  text-decoration: none;
  font-weight: 600;
  margin: 20px 0 0;
`;