import EventEmitter from 'eventemitter3';
import Cookies from 'js-cookie';

import { EventHandlers } from '../types/ApiTypes';
import { CreateEventHandlers } from './EventHandlers';

import {
  // BaseEvent,
  EventTypes
} from '../types/ApiTypes';

class API {
  client: WebSocket | null;
  emitter: EventEmitter<EventTypes>
  eventHandlers: EventHandlers;
  isReady: boolean;
  code: string;

  constructor(code: string) {
    this.emitter = new EventEmitter<EventTypes>();
    this.eventHandlers = CreateEventHandlers(this.emitter.emit);
    this.client = null;
    this.isReady = false;
    this.code = code;
    this.onOpen = this.onOpen.bind(this);
    this.sendMsg = this.sendMsg.bind(this);
  }

  init() {
    const stage = 'dev';
    const url = `wss://${stage}-ws.app.sesameaccess.com/?Authorization=${this.code}`;
    this.client = new WebSocket(url);
    this.client.onopen = this.onOpen;
    this.client.onmessage = this.onMessage;
    this.client.onerror = this.onError;
    this.client.onclose = this.onClose;
  }

  onOpen(event: any): void {
    this.isReady = true;

    if(this.code.length < 10) {
      this.sendMsg("jwt");
    }
  }

  onMessage(event: any): void {
    const data = JSON.parse(event.data);

    if(data.action === 'jwt' && data.success) {
      Cookies.set('jwt', data.jwt);
    }

    if(data.action === 'home') {
      Cookies.remove("jwt");
      alert('Thank you for using our services. See you again!');
    }

    if(data.action === 'inactive') {
      Cookies.remove("jwt");
      alert('Inactive. Closing connection.');
    }

    if(data.action === 'timeout') {
      Cookies.remove("jwt");
      alert('Timeout reached. Closing connection.');
    }
  }

  onError(event: any): void {
    console.log(JSON.stringify(event.data));
  }

  onClose(event: any): void {
    console.log(JSON.stringify(event.data));
  }

  sendMsg(payload: any): void {
    if(this.isReady) {
      this.client?.send(JSON.stringify({
        action: payload
      }));
      console.log('msg sent', payload);
    }
  }
}

export default API;
