import { Outlet } from 'react-router-dom';
import { StyledHeader, StyledLayout, Logo, StyledFooter, StyledFooterPhone, StyledFooterLogo, TextRegular, TextMedium, StyledMain } from './Layout.styled';

const Layout = () => {
  return (
    <StyledLayout>
      <StyledHeader>
        <Logo src="/logo.svg" />
      </StyledHeader>
      <StyledMain>
        <Outlet />
      </StyledMain>
      <StyledFooter>
        <StyledFooterLogo src="/logo-old.svg" />
        <StyledFooterPhone href="tel: 01784 440088">01784 440088</StyledFooterPhone>
        <TextRegular>
          Sesame Access Systems
          <br />Unit 1, Cumberland Works
          <br />Wintersell Business Park
          <br />West Byfleet
          <br />Surrey
          <br />KT14 7LF
          <br />United Kingdom
        </TextRegular>
        <TextMedium>The Disability Discrimination Act 2005 was superseded by the Equalities Act 2010. Any reference to Sesame Access complying with either Act is intended to imply that Sesame Access can help service providers meet their duties under the Equalities Act</TextMedium>
      </StyledFooter>
    </StyledLayout>
  );
};

export default Layout;