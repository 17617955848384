import useLongPress from 'hooks/useLongPress';
import { useContext, useState } from 'react';
import { ApiContext } from '../../providers/ApiProvider';
import { StyledButton, StyledHomeButtons } from './HomeButtons.styled';

type IntervalObj = Record<string, NodeJS.Timeout>
const interval: IntervalObj = {};

export const HomeButtons = () => {
  const [ activeButton, setActiveButton] = useState('');
  const context = useContext(ApiContext);

  const clearAllIntervals = () => {
    if(interval) {
      Object.keys(interval).forEach(el => clearInterval(interval[el]));
    }
  };

  const onLongPress = (action: string) => {
    setActiveButton(action);
    clearAllIntervals();
    interval[action] = setInterval(() => context?.api.sendMsg(action), 200);
  };

  const onCancel = () => {
    clearAllIntervals();
    setActiveButton('');
    context?.api.sendMsg('stop');
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 200
  };
    
  return (
    <StyledHomeButtons>
      <StyledButton
        active={activeButton === 'up'}
        image="up-down.png"
        option="up"
        {...useLongPress(() => activeButton === 'down' || activeButton === 'ret' ? null : onLongPress('up'), onCancel, defaultOptions)}
      >
        up
      </StyledButton>
      <StyledButton
        style={{ transform: 'rotate(180deg)' }}
        active={activeButton === 'down'}
        image="up-down.png"
        option="down"{...useLongPress(() => activeButton === 'up' || activeButton === 'ret' ? null : onLongPress('down'), onCancel, defaultOptions)}
      >
       down
      </StyledButton>
    </StyledHomeButtons>
  );
};