import { Button, TextRegular } from '../Layout/Layout.styled';
import { StyledSection } from './LegalsPage.styled';

export const LegalsPage = ({ triggerFunc }: { triggerFunc: () => void}) =>
  <StyledSection>
    <TextRegular>
        <ol>
            <li>
                This lift is for seated users only as the toe guard only protects wheelchairs wheels from rolling off the platform. This lift does not protect people who are standing from falling off.
            </li>
            <li>
                You will have the ability to move the stairs when controlling this lift so no one can be on the stairs when you begin to move them.
            </li>
            <li>
                The stairs must be extended when you have finished using the lift to allow others to use the staircase after you.
            </li>
        </ol>
        By clicking "Accept" you are agreeing to take responsibility for notes 1, 2, 3 and the safety of yourself and others whilst in control of this lift.
    </TextRegular>
    <Button isMain onClick={triggerFunc}>Accept</Button>
  </StyledSection>;
