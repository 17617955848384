import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { theme } from 'styled/theme';
import ApiProvider from './providers/ApiProvider';
import { NoMatch, Root } from './routes';
import Layout from 'components/Layout/Layout';
import { ThankYou } from 'components/ThankYou';

const App = () => {
  const code = window.location.search.split('=')[1];

  return (
    <BrowserRouter>
      <ApiProvider code={code}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/thank-you' element={<ThankYou />} />
            <Route path="/" element={<Layout />}>
              <Route index element={<Root />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </ApiProvider>
    </BrowserRouter>
  );
};

export default App;
