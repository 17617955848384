import { createContext, PropsWithChildren, useState } from 'react';
import API from 'api';
import Cookies from "js-cookie";

const useApiProviderSettings = (code: string) => {
  let finCode = code;
  const jwt = Cookies.get('jwt');

  if (jwt) {
    finCode = jwt;
  }

  const [api] = useState(() => {
    const initialState = new API(finCode);
    initialState.init();

    return initialState;
  });

  return { api };
};

type PrivateModeContextData = ReturnType<typeof useApiProviderSettings>;

export const ApiContext = createContext<PrivateModeContextData | null>(null);

// eslint-disable-next-line @typescript-eslint/ban-types
type ApiProviderProps = PropsWithChildren<{}>;

const ApiProvider = ({ code, children }: any) => {
  const { api } = useApiProviderSettings(code);

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};
export default ApiProvider;
